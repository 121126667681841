import { PlanType } from 'Utils/enums';

type Environment = 'dev' | 'staging' | 'production';

const environment: Environment = (process.env.REACT_APP_ENV || 'dev').trim() as Environment;
const apiVersion: { [key: string]: string } = {
  dev: 'v0',
  staging: 'v0',
  production: 'v0',
};
export const API_VERSION = apiVersion[environment];
const baseUrl: { [key: string]: string } = {
  dev: `https://api-dev.docudent.app/api/${API_VERSION}/`,
  staging: `https://api-staging.docudent.app/api/${API_VERSION}/`,
  production: `https://api.docudent.ai/api/${API_VERSION}/`,
};
const s3Url: { [key: string]: string } = {
  dev: 'https://dev-public.docudent.app/',
  staging: 'https://staging-public.docudent.app/',
  production: 'https://production-public.docudent.ai/',
};

const s3UrlCase: { [key: string]: string } = {
  dev: 'https://s3.us-east-1.amazonaws.com/dev-patient-case.docudent.app/',
  staging: 'https://s3.us-east-1.amazonaws.com/staging-patient-case.docudent.app/',
  production: 'https://s3.us-east-1.amazonaws.com/production-patient-case.docudent.ai/',
};

const stripeKey: { [key: string]: string } = {
  dev: 'pk_test_51OOfQUDOxjkR728uWxhN0twRbV1fEViWBCHNAjubYBWmc3FcXQtDYigLbc83n3z1XhFUWfhDdWNPOTbxf2ErJEWl00yeiN8eCa',
  staging:
    'pk_test_51OOfisLD3SEtlfAtaxrFmLZatuELRD8eSCzydX7e0dmw53HUfjwQVEo81VWe2mWzpyFTN9hmAhVAhKzffALbClXV00Bue7iUsa',
  // production:
  //   'pk_live_51PVGhD06t5he9wEjBtncf8erzHBWvyN7AUIoTiokbM0UwKzxYhNSqr6gtuHUklb67ocxE65WDfKyQqyRPUQPYc8e001vsqZYSI',
  production:
      'pk_test_51PVGhD06t5he9wEj0tEOjaMWGCXv4delO1Ktu2KQ8bVbAvvlaMNLEPsrIrvywLg7JsVPFhrKCOaWqoqZzptE7xe5008Sc4dT0w',
};

const priceList: any = {
  dev: {
    [PlanType.FREE]: 'price_1PImLgDOxjkR728ujx3xZoUw',
    [PlanType.UNLIMITED]: {
      yearly: 'price_1PImKJDOxjkR728uN9oKWY5W',
      monthly: 'price_1PImJrDOxjkR728uYUBJkOqO',
    },
    [PlanType.TRADITIONAL]: {
      yearly: 'price_1PPnr3DOxjkR728uquR8WBbR',
      monthly: 'price_1P7w4dDOxjkR728u4PH5Sy2k',
    },
  },
  staging: {
    [PlanType.FREE]: 'price_1PPnmpLD3SEtlfAtStuYW4GQ',
    [PlanType.UNLIMITED]: {
      yearly: 'price_1PPnnPLD3SEtlfAthqZ9Abn0',
      monthly: 'price_1PPnnxLD3SEtlfAtOs51WBee',
    },
    [PlanType.TRADITIONAL]: {
      yearly: 'price_1PPnr0LD3SEtlfAtCRRB9YMk',
      monthly: 'price_1PPnuILD3SEtlfAtjxlH1cTs',
    },
  },
  // production: {
  //   [PlanType.FREE]: 'price_1PPnmpLD3SEtlfAtStuYW4GQ',
  //   [PlanType.UNLIMITED]: {
  //     yearly: 'price_1PeYOU06t5he9wEjo25tZ4vw',
  //     monthly: 'price_1PeYOU06t5he9wEjdz6xn5od',
  //   },
  //   [PlanType.TRADITIONAL]: {
  //     yearly: 'price_1PeYOU06t5he9wEjWzmdmJ8K',
  //     monthly: 'price_1PeYOU06t5he9wEjIJZg8fYP',
  //   },
  // },

  production: {
    [PlanType.FREE]: 'price_1PeYMl06t5he9wEjtilYOLyd',
    [PlanType.UNLIMITED]: {
      yearly: 'price_1PeYMM06t5he9wEjTh56hoj7',
      monthly: 'price_1PeYMM06t5he9wEjgslyomFK',
    },
    [PlanType.TRADITIONAL]: {
      yearly: 'price_1PeYMM06t5he9wEjw8uqAu0J',
      monthly: 'price_1PeYMM06t5he9wEjmtYzt6kK',
    },
  },
};

export const INITIAL_ROUTE = '/control-panel';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const ZIP_CODE_FORMAT = "XXXXX  or  XXXXX-XXXX";
export const BASE_URL = baseUrl[environment];
export const S3_URL = s3Url[environment];
export const S3_URL_CASE = s3UrlCase[environment];
export const STRIPE_KEY = stripeKey[environment];
export const PRICE_LIST = priceList[environment];
