// utils/helpers.js
import { notification } from "antd";
import { fetchData } from "./fetch";
import { restAPIs } from "./restAPIs";
import { Modal } from 'antd';
import { Auth } from "aws-amplify";
import { OptionType } from "./interfaces";
import { PlanType, SubscriptionStatus } from "./enums";
import { planMap } from "Pages/Settings/helpers";
import { SubscriptionDetails } from "reducers/types";
import { ToothDetails } from "./types";
import { EffectTypeEnum } from "./treatments/v2/effects";
const { confirm } = Modal;
type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const hasEffect = (tooth: ToothDetails, effectType: EffectTypeEnum) => {
  return tooth.effects?.some((e) => e.type === effectType);
}
export const findEffect = (tooth: ToothDetails, effectType: EffectTypeEnum) => {
  return tooth.effects?.find((e) => e.type === effectType);
}

export const generateRandomId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  
  return "id-" + result;
}

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
}

export const validateEmail = (input: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input.trim());
};

export const getCurrentUserDetails = async () => {
  const res = await fetchData(restAPIs.getLoggedUser);
  return res
}

export const validateZipCode = (rule: any, value: string) => {
  const zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
  if (!value || zipCodePattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid Zip code.");
};

export const pluralize = (count: number, key: string) => {
  return `${key}${count > 1 ? "s" : ""}`;
};

export const handleSignOut = () => {
  return new Promise(() => {
    confirm({
      title: 'Logout Confirmation',
      className: 'confirm-modal',
      transitionName: "",
      maskTransitionName: "",
      okText: 'Yes',
      content: 'Are you sure you want to log out?',
      onOk: async () => {
        try {
          await Auth.signOut();
        } catch (error) {
          console.log('Error signing out: ', error);
        }
      },
      onCancel: () => { },
    });
  });
};

export const countySelectSort = (a: OptionType, b: OptionType) => {
  // Move 'US' to the top
  if (a.value === 'US') return -1;
  if (b.value === 'US') return 1;
  // Sort other options alphabetically
  return a.label.localeCompare(b.label);
};

export const disabledDate = (current: any) => {
  return current && current > new Date();
};

export const openNotificationWithIcon = (message: string, type?: NotificationType, title?: string) => {
  notification[type || "error"]({
    message: title || "Error",
    description: message || 'Something went Wrong',
  });
};

export const isSSNFormat = (input: string): boolean => {
  const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
  return ssnPattern.test(input);
};

export const formatSSN = (ssn: string) => {
  return ssn.replace(/\D/g, '').substring(0, 4);
};


export const getFilteredObject = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== '')
  );
}

export const calculatePercentage = (value: number, total: number): number => {
  if (total === 0 || value === 0) return 0; // Return 0 if either value or total is 0
  return Math.round((value / total) * 100);
};

export const hexToRgba = (hex: string, opacity = 30) => {
  // Remove the '#' character if present
  hex = hex.replace('#', '');

  // Parse the hex string to get the RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string with the given opacity
  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
}

export const caseTypeOptions = [
  {
    value: "crown",
    label: "Crown",
  },
  {
    value: "implant",
    label: "Implant",
  },
  {
    value: "removable",
    label: "Removable",
  },
  {
    value: "implantRemovable",
    label: "Implant Removable",
  },
];
export const caseStatusOptions = [
  {
    value: "onHold",
    label: "On hold",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "inProgress",
    label: "In Progress",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

export const bytesToMB = (bytes: number) => {
  return (bytes / (1024 * 1024)).toFixed(2);
}

export const getUserName = (createdBy: any) => {
  return `${createdBy?.firstName} ${createdBy?.lastName}` || '-'
}

export const capitalizeWords = (str: string) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const snakeCaseToTitleCase = (str: string) => {
  return str?.split('_')?.map(word => capitalizeWords(word))?.join(' ');
}

export const getFormattedPlanName = (subscriptionDetails: SubscriptionDetails) => {
  const planType = subscriptionDetails?.planType as PlanType;
  let formattedPlanName =
    planType && planMap[planType] ? planMap[planType] : "Free";
  if (
    subscriptionDetails?.planType === PlanType.TRADITIONAL &&
    subscriptionDetails?.totalLicenseCount === 1
  ) {
    formattedPlanName = "Basic";
  }
  return formattedPlanName;
}

export const validateNotEmpty = (_: any, value: string) => {
  if (!value || value.trim() === "") {
    return Promise.reject(new Error("Required Field"));
  }
  return Promise.resolve();
};

export const selectCustomFilterOption = (input: string, option: any) =>
  option.label.toLowerCase().includes(input.toLowerCase());

export const formatDate = (date: string, useLongFormat?: boolean): string => {
  // Parse the date and check for validity
  const parsedDate = new Date(date);

  if (useLongFormat) {
    // Long format: Month Day, Year
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return parsedDate.toLocaleDateString('en-US', options);
  } else {
    // Short format: mm/dd/yyyy
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = parsedDate.toLocaleDateString('en-US', options);

    // Split the formatted date to extract month, day, and year
    const [month, day, year] = formattedDate.split('/');

    // Format the date as mm/dd/yyyy
    return `${month}/${day}/${year}`;
  }
};

export const calculateAge = (birthDate: Date): number | null => {

  if (!birthDate || isNaN(birthDate?.getTime())) {
    console.log("Invalid birth date");
    return null;
  }
  const today = new Date();
  const birthDateUtc = new Date(birthDate?.toUTCString());
  const age = today?.getUTCFullYear() - birthDateUtc?.getUTCFullYear();
  const monthDiff = today?.getUTCMonth() - birthDateUtc?.getUTCMonth();

  // Adjust age if birth date month is greater than current month or
  // if birth date month is the same as current month but the day is greater
  if (monthDiff < 0 || (monthDiff === 0 && today?.getUTCDate() < birthDateUtc?.getUTCDate())) {
    return age - 1;
  }

  return age;
}

export const hasUpperCase = (str: string) => /[A-Z]/.test(str);

export const getPasswordError = (value: string) => {
  let passwordError: string = "";
  const str = value?.trim()
  if (str.length < 8) {
    passwordError = "Password must be 8 characters or longer!";
  } else if (!hasUpperCase(str)) {
    passwordError = "Please use at least one capital letter!";
  } else if (!hasNumberOrSpecialChar(str)) {
    passwordError = "Please use at least one number or special character!";
  }

  return passwordError;
}

export const hasNumberOrSpecialChar = (str: string) => /[0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(str);

export const isLowerTeeth = (toothId: string) => toothId.startsWith('L');
export const isUpperTeeth = (toothId: string) => toothId.startsWith('U');

export const isBothTeethOnSameSide = (tooth1: string, tooth2: string) => {
  return isLowerTeeth(tooth1) === isLowerTeeth(tooth2) || isUpperTeeth(tooth1) === isUpperTeeth(tooth2);
}

export function camelToSnakeCase(input: string): string {
  return input?.replace(/[A-Z]/g, (match) => ` ${match.toLowerCase()}`);
}

export function checkExpiry(status: SubscriptionStatus): boolean {
  return ([
    SubscriptionStatus.Expired,
    SubscriptionStatus.Incomplete,
    SubscriptionStatus.IncompleteExpired,
    SubscriptionStatus.Paused,
    SubscriptionStatus.Canceled,
  ].includes(status));
}


export const getFormattedToothNumber = (inputString: string): number | null => {
  // Check if the string matches the format "U" followed by a number
  const uMatch = inputString.match(/^U(\d+)$/);
  if (uMatch) {
    const number = parseInt(uMatch[1], 10);
    if (number >= 1 && number <= 16) {
      return number;
    }
  }

  // Check if the string matches the format "L" followed by a number
  const lMatch = inputString.match(/^L(\d+)$/);
  if (lMatch) {
    const number = parseInt(lMatch[1], 10);
    if (number >= 1 && number <= 16) {
      return number + 16; // Offset for L1 to L16 (17 to 32)
    }
  }

  // Return null if the string doesn't match the expected formats or number is out of range
  return null;
}
