// Core
import React, { useEffect, useState, Suspense } from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useDispatch } from "react-redux";

// Styles

// Others
import { ActionTypes, AuthStatus, SubscriptionStatus } from "Utils/enums";
import { checkExpiry, getCurrentUserDetails } from "Utils/helpers";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";
import SidebarLayout from "./SidebarLayout";
import MyOffice from "Pages/MyOffice";
import Invite from "Pages/Invite";
import { useUserDetails } from "hooks/useUserDetails";
import SubscribedRoute from "./SubscribedRoute";
import ProfileSetupRoute from "./ProfileSetupRoute";
import RenewSubscriptionRoute from "./RenewSubscriptionRoute";
import { INITIAL_ROUTE } from "config/constants";
import PageNotFound from "Components/UIBlocks/404";

// Components
import Loader from "Components/Loader";
import PatientDetails from "Pages/PatientDetails/loadable";
import AddOrEditPatient from "Pages/AddOrEditPatient/loadable";
import Patients from "Pages/Patients/loadable";
import Settings from "Pages/Settings/loadable";
import CreateCase from "Pages/CreateCase/loadable";
import CaseDetails from "Pages/CaseDetails/loadable";
import EditLocation from "Pages/LocationEdit/loadable";
import EditTimeline from "Pages/TimelineEdit/loadable";
import ForgotPassword from "Pages/Auth/ForgotPassword/loadable";
import ConfirmEmail from "Pages/Auth/ConfirmEmail/loadable";
import Login from "Pages/Auth/Login/loadable";
import PrivacyPolicy from "Pages/PrivacyPolicy/loadable";
import TermsAndCondition from "Pages/TermsAndCondition/loadable";
import ChangeSubscription from "Pages/ChangeSubscription/loadable";
import ResetPassword from "Pages/Auth/ResetPassword/loadable";
import SignUp from "Pages/Auth/Signup/loadable";
import ControlCenter from "Pages/ControlCenter/loadable";

const AppRouter: React.FC = () => {
  const { userDetails } = useUserDetails();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<SubscriptionStatus | undefined>(undefined);
  const dispatch = useDispatch();
  const isAuthenticated = authStatus === AuthStatus.AUTHENTICATED;

  useEffect(() => {
    setStatus(userDetails?.status);
  }, [userDetails]);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }
    const getUserDetails = async () => {
      if (window.location.pathname === "/invite") {
        setStatus(SubscriptionStatus.Invite);
        return;
      }
      try {
        setLoading(true);
        const res = await getCurrentUserDetails();
        const {
          data: { status = "", organizations = [] },
        } = res;
        const defaultOrg = organizations.find((item: any) => item.isDefault);
        if (defaultOrg) {
          const orgDetails = await fetchData(
            restAPIs.getOrganizationDetails(defaultOrg.organizationId)
          );
          const {
            data: { subscriptionDetails, subscriptionId, name },
          } = orgDetails;
          const isInvitedUser =
            status === "EmailVerified" &&
            subscriptionDetails?.status === SubscriptionStatus.Active;
          let currentStatus =
            subscriptionDetails?.status === SubscriptionStatus.Active
              ? SubscriptionStatus.Active
              : status;
          if (isInvitedUser) {
            currentStatus = status;
          }
          if (checkExpiry(subscriptionDetails?.status)) {
            currentStatus = subscriptionDetails?.status;
          }
          dispatch({
            type: ActionTypes.USER_DETAILS,
            value: {
              ...res?.data,
              status: currentStatus,
              subscriptionId,
              isInvitedUser,
              isPaidPlan: subscriptionDetails?.isPaidPlan,
              currentOrg: defaultOrg,
              currentSubscription: orgDetails?.data,
              organizationName: name,
              organizationId: defaultOrg.organizationId,
            },
          });
          setStatus(currentStatus);
        } else {
          // Handle the case when there is no default organization
          console.log("No default organization found");
        }
      } catch (error) {
        // Handle errors here
        console.log("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    getUserDetails();
  }, [isAuthenticated, dispatch]);

  if ([AuthStatus.CONFIGURING].includes(authStatus as any) || loading)
    return <Loader size="large" fullscreen />;
  if (authStatus === AuthStatus.AUTHENTICATED && !status)
    return <Loader size="large" fullscreen />;

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader size="large" fullscreen />}>
        <Routes>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            <Route
              path="/profile-setup"
              element={<ProfileSetupRoute status={status} />}
            />
            <Route
              path="/renew-subscription"
              element={<RenewSubscriptionRoute status={status} />}
            />
            <Route element={<SubscribedRoute status={status} />}>
              <Route element={<SidebarLayout />}>
                <Route path="/" element={<Navigate to={INITIAL_ROUTE} replace />} />
                <Route path="/patient" element={<Patients />} />
                <Route
                  path="/patient/:id/case/create"
                  element={<CreateCase />}
                />
                <Route
                  path="/patient/:id/case/:caseId"
                  element={<CaseDetails />}
                />
                <Route
                  path="/patient/:id/case/:caseId/location"
                  element={<EditLocation />}
                />
                <Route
                  path="/patient/:id/case/:caseId/timeline"
                  element={<EditTimeline />}
                />
                <Route path="/patient/create" element={<AddOrEditPatient />} />
                <Route path="/patient/:id" element={<AddOrEditPatient />} />
                <Route
                  path="/patient/:id/details"
                  element={<PatientDetails />}
                />
                <Route path="/my-office" element={<MyOffice />} />
                <Route path="/settings" element={<Settings />} />
                <Route
                  path="/update-subscription-plan"
                  element={<ChangeSubscription />}
                />
                <Route
                  path="/control-panel"
                  element={<ControlCenter />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="/invite" element={<Invite />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default AppRouter;
