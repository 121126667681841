// Core
import React, { useEffect } from "react";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import Avatar from "antd/es/avatar";
import Dropdown from "antd/es/dropdown";

// Components

// Styles
import { Flex } from "@rebass/grid";

// Others
import { colors } from "Assets/Styles/colors";
import { FontSizes } from "Utils/enums";
import { Label } from "Assets/Styles/globalStyles";
import { useUserDetails } from "hooks/useUserDetails";
import { handleSignOut } from "Utils/helpers";
import { S3_URL } from "config/constants";

const ProfileHead: React.FC = () => {
  const { userDetails, personalInformation, updateUserCounts } =
    useUserDetails();

  useEffect(() => {
    if (userDetails?.status) updateUserCounts();
  }, []);

  const dropDownItem: any = [
    {
      label: "Sign Out",
      key: "0",
      onClick: handleSignOut,
    },
  ];

  if (!userDetails?.status) return null;
  return (
    <Dropdown menu={{ items: dropDownItem }} trigger={["click"]}>
      <div onClick={(e) => e.preventDefault()} style={{ cursor: "pointer" }}>
        <Flex alignItems="center">
          <Label
            color={colors.primaryText}
            fontSize={FontSizes.h6}
            marginRight={7}
            pointer
          >
            {personalInformation?.firstName
              ? `${personalInformation?.firstName} ${
                  personalInformation?.middleName || ""
                } ${personalInformation?.lastName}`
              : userDetails?.email}
          </Label>
          <Avatar
            src={
              personalInformation?.profilePicKey
                ? `${S3_URL}${personalInformation.profilePicKey}`
                : null
            }
            icon={<UserOutlined />}
          />
          <DownOutlined
            style={{
              color: colors.primaryText,
              marginLeft: "4px",
              fontSize: "0.7rem",
            }}
          />
        </Flex>
      </div>
    </Dropdown>
  );
};

export default ProfileHead;
