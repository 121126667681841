import styled from "styled-components";
import { Table } from "antd";

// Types
import { FontSizes } from "Utils/enums";
import { colors } from "../../Assets/Styles/colors";


export const TableWrapper = styled(Table)`

  ul {
    padding-left: 15px;
  }

  .ant-table-thead {
    th {
      border: 1px solid ${colors.blue2};
      border-bottom: 1px solid ${colors.blue2} !important;

      &:not(:last-child) {
        border-right: none;
      }
      &:not(:first-child) {
        border-left: none;
      }
    }
    
    .ant-table-cell {
      background: ${colors.blue1};
      font-weight: 500;
      padding: 10px 17px;
    }
  }

  .ant-spin-nested-loading >div>.ant-spin .ant-spin-dot {
    top: 60%;
  }

  .ant-table-cell {
    font-size: ${FontSizes.table}rem;
    line-height: 1rem;

    &::before {
      display: none;
    }
  }

  .ant-table-tbody {

    tr {
      &:nth-child(even) {
        background-color: ${colors.blue5};
      }
    }
    td {
      padding: 10px 15px !important;
      border-bottom: 1px solid ${colors.grey4} !important;
      border: none;

      &:last-child {
        border-right: 1px solid ${colors.grey4};
      }
      &:first-child {
        border-left: 1px solid ${colors.grey4};
      }
    }

    .ant-btn-dashed {
      display: flex;
      align-items: center;
      justify-content: center;
      .anticon {
        color: ${colors.primary};
        font-size: ${FontSizes.tableAction}rem;
      }
    }
  }

  .ant-table-pagination  {
    a {
      font-weight: normal;
    }

    .ant-pagination-prev, .ant-pagination-next, .ant-pagination-item {
      min-width: 28px;
      height: 28px;
    }

    .ant-pagination-prev {
      margin-inline-end: 4px;
    }

    .ant-pagination-item {
      border-radius: 50%;
      font-size: ${FontSizes.table}rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-end: 4px;
    }

    .ant-pagination-item-active {
      background-color: ${colors.primary};
      border-color: ${colors.primary};

      a {
        color: white;
      }
     }
  }

  .ant-tag {
      font-size: ${FontSizes.tag}rem;
      padding-inline: 10px;
      line-height: 19px;
      border: none;
      color: ${colors.primaryText} !important;
    }

    .ant-spin-blur {
      opacity: 1;
    }

    .ant-spin-container::after  {
      top: 40px !important;
      height: calc(100% - 40px) !important;
    }
`;