// Core
import React from "react";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { QueryClient, QueryClientProvider } from "react-query";

// Others
import config from "./amplifyconfiguration.json";
import AppRouter from "routes";
import ErrorBoundary from "ErrorBoundary";

// Styles
import { GlobalStyle } from "Assets/Styles/globalStyles";
import { RecoilRoot } from "recoil";

Amplify.configure(config);

const queryClient = new QueryClient();

export function App() {
  return (
    <Authenticator.Provider>
      <GlobalStyle />
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <AppRouter />
          </RecoilRoot>
        </QueryClientProvider>
      </ErrorBoundary>
    </Authenticator.Provider>
  );
}

export default App;
