export type Props = {
  formFields?: any;
  title: string;
  loading: boolean;
  noFilter?: boolean;
  hideAdd?: boolean;
  hideTitle?: boolean;
  singleRow?: boolean;
  addNewBtnLabel?: string;
  handleAdd?: () => void;
  fetchData: (formData: any) => void;
};


export const FieldType = {
  TF: 'TF',
  CB: 'CB',
  SB: 'SB', 
  DS: 'DS',
  ORG_USER: 'ORG_USER',
}