import { ActionTypes, OrganizationRoleEnum, PlanType } from "Utils/enums";
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";
import { useDispatch, useSelector } from "react-redux";
import { RootState, UserDetailsState } from "reducers/types";

export const useUserDetails = () => {
  const dispatch = useDispatch();
  const userDetailsState = useSelector<RootState, UserDetailsState>(
    (store) => store.userDetailsReducer
  );

  const { currentSubscription, currentOrg, userDetailsCount } =
    userDetailsState;

  const isAdmin: boolean = userDetailsState?.currentOrg?.roles?.some((role) =>
    [OrganizationRoleEnum.OrganizationOwner].includes(role)
  );

  const updateUserCounts = async () => {
    const res = await fetchData(restAPIs.getFollowupCount);
    if (res?.data) {
      dispatch({
        type: ActionTypes.UPDATE_USER_COUNTS_INFORMATION,
        value: {
          value: {
            ...res.data,
            totalFollowups:
              res.data.Low +
              res.data.Medium +
              res.data.Urgent +
              res.data.Resolved,
          },
          key: "followupCount",
        },
      });
    }
  };

  const isStaff: boolean =
    currentOrg?.roles?.length === 1 && currentOrg?.roles[0] === "Staff";

  const isPaidPlan: boolean = userDetailsState?.isPaidPlan ?? false;
  const havePatientAccess: boolean =
    isPaidPlan &&
    currentOrg?.roles?.some((role) =>
      [
        OrganizationRoleEnum.OrganizationOwner,
        OrganizationRoleEnum.Doctor,
      ].includes(role)
    );

  const haveCaseAccess: boolean = currentOrg?.roles?.some((role) =>
    [
      OrganizationRoleEnum.OrganizationOwner,
      OrganizationRoleEnum.Doctor,
    ].includes(role)
  );

  const isOrgOwner: boolean =
    currentOrg?.roles?.length === 1 &&
    currentOrg?.roles[0] === OrganizationRoleEnum.OrganizationOwner;

  return {
    userDetails: userDetailsState,
    isAdmin,
    havePatientAccess,
    haveCaseAccess,
    isStaff,
    updateUserCounts,
    userDetailsCount,
    userLicenseRemaining:
      (currentSubscription?.subscriptionDetails?.totalLicenseCount ?? 0) -
      (currentSubscription?.activeOrgUsersCount ?? 0),
    isUnlimitedPlan:
      currentSubscription?.subscriptionDetails?.planType === PlanType.UNLIMITED,
    isPaidPlan,
    currentSubscription: userDetailsState?.currentSubscription,
    isOrgOwner,
    personalInformation: userDetailsState?.personalInformation,
    userId: userDetailsState?._id,
    organizationId: userDetailsState?.organizationId,
  };
};
