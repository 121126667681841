import React from "react";
import { Box, Flex } from "@rebass/grid";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tag } from "antd";

// Styles
import { CustomTag, Label } from "Assets/Styles/globalStyles";

// Types
import { FontSizes } from "Utils/enums";
import { S3_URL } from "config/constants";
import { colors } from "Assets/Styles/colors";

type Props = {
  url?: string;
  label: string;
  isPointer?: boolean;
  tag?: string;
  email?: string;
  followUpCount?: number;
};

const tagStyle = { marginTop: "3px" };

const UserHead: React.FC<Props> = (props: Props) => {
  const { url = null, label, tag, isPointer, email, followUpCount } = props;
  
  return (
    <Flex alignItems="center" style={{ minWidth: "180px" }}>
      <Avatar src={url ? `${S3_URL}${url}` : null} icon={<UserOutlined />} />
      <Box marginLeft="5px">
        <Label
          pointer={isPointer}
          color={colors.primaryText}
          fontSize={FontSizes.table}
        >
          {label}
        </Label>
        {email && (
          <Label
            pointer={isPointer}
            marginTop={-2}
            fontSize={FontSizes.floatingLabel}
            color={colors.secondaryText}
          >
            {email}
          </Label>
        )}
        {tag && (
          <Tag style={tagStyle} color="processing">
            {tag}
          </Tag>
        )}
      </Box>
      {!!followUpCount && (
        <Box marginLeft="5px">
          <CustomTag type="error">
            {followUpCount} Follow-up Request
          </CustomTag>
        </Box>
        )}
    </Flex>
  );
};

export default UserHead;
