import {
  UsergroupAddOutlined,
  SettingOutlined,
   ControlOutlined,
  BankOutlined,
} from "@ant-design/icons";

export const sideBarMenu = [
  // {
  //   icon: <BlockOutlined  />,
  //   label: "Dashboard",
  //   path: "/",
  // },
  {
    icon: <ControlOutlined />,
    label: "Control Panel",
    activeFor: '/control-panel',
    path: "/control-panel",
  },
  {
    icon: <UsergroupAddOutlined />,
    label: "Patients",
    activeFor: '/patient',
    path: "/patient",
  },
  {
    icon: <BankOutlined />,
    label: "My Office",
    activeFor: '/my-office',
    path: "/my-office",
  },
  {
    icon: <SettingOutlined />,
    label: "Settings",
    activeFor: '/settings',
    path: "/settings",
  },
];
