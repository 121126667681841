import styled, { createGlobalStyle, css } from "styled-components";
import "react-phone-input-2/lib/style.css";
// Types
import { GridProps, LabelProps } from "./types";
import { FontSizes, FontWeight } from "Utils/enums";
import { colors } from "./colors";

export const GlobalStyle = createGlobalStyle`

    a {
        color: ${colors.primary};
        text-decoration: none;
        cursor: pointer;
    }

    main {
      position: relative;
    }
    
    * {
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif !important;
    }

    body {
        margin: 0;
        padding: 0;
        font-size: 16px !important;
    }

    b {
      font-weight: 500;
    }

    img {
        max-width: 100%;
        vertical-align: middle;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: disc;

      li {
        padding: 0;
      }
    }

    textarea.ant-input {
      font-size: ${FontSizes.input}rem;
      min-height: 150px;
    }

    input::placeholder {
    font-size: ${FontSizes.floatingLabel}rem;
    color: ${colors.grey1} !important;
  }

  .ant-table-measure-row {
    display: none;
  }

  .ant-input[disabled] {
    color: ${colors.secondaryText};
  }

  .ant-input-data-count {
    bottom: -20px;
    font-size: ${FontSizes.floatingLabel}rem;
  }

  .ai-custom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-tel-input  {
    .form-control  {
      height: 40px !important;
      width: 100% !important;
      font-size: ${FontSizes.input}rem !important;
      border: 1.5px solid ${colors.grey2} !important;
    }
  }

  .ant-form-item-explain-error {
    font-size: ${FontSizes.error}rem;
  }

    .case-type-radio {
      width: 100%;

      .ant-radio-wrapper, .ant-space {
        width: 100%;
      }
      .ant-radio-wrapper {
        span:nth-child(2) {
          flex: 1;
        }
      }


      .ant-radio {
        align-self: flex-start;
        margin-top: 2px;
      }

      ul {
        background-color: ${colors.blue6};
        list-style: decimal;
        padding: 10px 20px;
        border-radius: 8px;
        margin: 10px 0 5px;
        margin-left: -23px;
        
        li {
          font-size: ${FontSizes.floatingLabel}rem;
          margin-left: 5px;
          margin-bottom: 2px;
        }
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .secondary-tab {
      .ant-tabs-nav .ant-tabs-tab {
        padding: 2px 10px 8px;
      }

      .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: ${FontSizes.table}rem;
      }

      .ant-tabs-nav {
        margin-bottom: 10px;
      } 

      .ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
        margin-left: 10px;
      }
    }

  .ant-input {
    height: 40px !important;
    border-width: 1.5px !important;
    border-radius: 6px;
    border-color:  ${colors.grey2};
    font-size: ${FontSizes.input}rem !important;
    background-color: white !important;
  }


    .ant-form-vertical .ant-form-item-label {
      padding: 0 0 0px;
    }

    .ant-form .ant-form-item-label label{
      color: ${colors.primaryText};
    font-size: ${FontSizes.floatingLabel}rem !important;
    } 

    .ant-select-dropdown {
      .ant-select-item-option-content {
        font-size: ${FontSizes.input}rem;
        color: ${colors.primaryText};
      }
    }

    .ant-select {
      height: 40px;
      width: 100% !important;
      
      .ant-select-selection-item {
        font-size: ${FontSizes.input}rem;
        color: ${colors.primaryText};
      }
      
      .ant-select-selector {
        border: 1.5px solid ${colors.grey2};
        border-radius: 6px;
      }
    }

    .ant-select-selection-placeholder {
      font-size: ${FontSizes.floatingLabel}rem !important;
      color: ${colors.grey1} !important;
    }

    .ant-tooltip-inner {
      ul {
        list-style: disc;
        padding-left: 15px;
      }

      font-size: ${FontSizes.input}rem;
      font-weight: ${FontWeight.default};
    }

    .ant-empty .ant-empty-image {
      height: 70px !important;
    }

    .ant-empty-normal .ant-empty-image {
      height: 40px !important;
    }

    .ant-empty-description {
      font-size: ${FontSizes.input}rem;
      color: ${colors.secondaryText} !important;
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 12px;

      span {
        font-size: ${FontSizes.input}rem;

        small {
          color: ${colors.secondaryText}
        }
      }
    }

    .password-indicator {
      margin-top: 10px;
      margin-bottom: 40px;
      & > div:first-child {
         div {
            height: 3px !important;
            border-radius: 2px;
         }
      }

      p {
        margin-right: 10px !important;
        color: ${colors.primaryText} !important;
        font-size: ${FontSizes.error}rem !important;
        text-align: left !important;
      }
    }

    .ant-collapse.no-panel-collapse {
      border: none;
      background-color: transparent !important;

      .ant-collapse-header {
        display: none !important;
      }

      .ant-collapse-item {
        border: none !important;
      }

      .ant-collapse-content {
        border: none !important;
        background-color: transparent;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }

    .ant-collapse  {
      background-color: white;
      border: 1px solid ${colors.border};

      .ant-collapse-item {
        border-color: ${colors.border};
        &:last-child {
          border: none;
        }
      }

      .ant-collapse-content {
        border-color: ${colors.border};
      }
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 10px 15px;
        cursor: pointer;
        .ant-tabs-tab-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: ${colors.secondaryText};

          .anticon {
            margin: 0;
            margin-bottom: 5px;
            font-size: ${FontSizes.sidebarIcon}rem;
          }
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn { 
          color: ${colors.primary} !important;
        }
      }

      .ant-tabs-ink-bar {
        background-color: ${colors.primary}
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin-left: 15px;
      }
    }

    .ant-modal { 
      .ant-modal-content {
        padding: 0;
      }

      .ant-modal-header, .ant-modal-body, .ant-modal-footer {
        padding: 15px 25px;
      }

      .ant-modal-header {
        padding-top: 25px;
        border-bottom: 1px solid ${colors.border};
      }

      .ant-modal-footer {
        padding-bottom: 25px;
        border-top: 1px solid ${colors.border};

        .ant-btn-dashed {
          border: none !important;
          box-shadow: none;
        }
      }

      .ant-modal-body {}
    }

    .confirm-modal {
      .ant-modal-content {
        padding: 20px;
      }

      .ant-modal-confirm-btns {
        margin-top: 20px;
      }
    }

    .ant-btn-primary {
      background: ${colors.primary};
    }
    
    .ant-btn-default {

      &:hover {
        color: ${colors.primaryText} !important;
        border-color: ${colors.grey5} !important;
      }
    }

    .ant-modal-confirm-btns {
      .ant-btn {
      font-size: ${FontSizes.button}rem !important;
      }
    }

    .ant-btn-sm {
    font-size: ${FontSizes.button}rem !important;
    }

    .ant-picker .ant-picker-input >input {
      font-size: ${FontSizes.input}rem;
    }

    .ant-dropdown-menu-title-content {
      font-size: ${FontSizes.floatingLabel}rem;
    }
    .ant-breadcrumb {
      font-size: ${FontSizes.h6}rem;

      li {
        &:last-child {
          font-weight: ${FontWeight.medium};
        }
      }
    }

    .ant-layout {
      background-color: white;
    }

    .ant-btn-link {
      height: auto;
      font-size: ${FontSizes.floatingLabel}rem;
    }

    .ant-layout-content {
      background-color: ${colors.grey3};
      padding: 20px 20px 30px;
    }

    .ant-layout-sider {
      background-color: ${colors.darkPrimary2} !important;
    }
    .ant-switch.ant-switch-checked {
      background-color: ${colors.primary};
    }

    .ant-btn-dashed {
      background: white !important;
      color: ${colors.primaryText} !important;
      border: 1.5px solid ${colors.blue1} !important;
    }

    .ant-radio-group-solid {
      background-color: ${colors.blue1};
    }

    .ant-radio-group {
      border-radius: 20px;

      .ant-radio-button-wrapper:not(:first-child)::before {
        content: none;
       }

      .ant-radio-button-wrapper {
        line-height: 25px;
        height: 25px;
        padding: 0 25px;
        color: ${colors.secondaryText};
      }

      .ant-radio-button-wrapper-checked {
        background-color: ${colors.primary} !important;
      }

      * {
        border: none;
      }
      .ant-radio-button-wrapper {
        border-radius: 20px;
        background-color: ${colors.blue1};
        font-size: ${FontSizes.floatingLabel}rem;

        &:first-child {
          border-inline-start: none
        }
      }
    }

    .ant-spin-fullscreen {
      background-color: white;
    }

    .ant-spin-fullscreen .ant-spin-dot .ant-spin-dot-item {
    background-color: ${colors.primary};
    }

    .ant-picker {
      border: 1.5px solid ${colors.grey2};
      border-radius: 6px;
      font-size: 0.8rem;
      height: 40px;
      width: 100%;

      input::placeholder {
      font-size: ${FontSizes.floatingLabel}rem;
      color: ${colors.grey1};
      }
    }

    .ant-checkbox-wrapper {
        text-align: left;
        line-height: 1.1rem;
        .ant-checkbox {
            align-self: flex-start;
        }

        .ant-checkbox-inner {
            border-color: ${colors.primary};
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${colors.primary};       
            &:hover {
              background-color: ${colors.primary} !important;
            }
        }
    }

    .checkbox-error {
      .ant-checkbox-inner {
            border-color: ${colors.error};
        }
    }
`;

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.col || 1}, 1fr);
  grid-gap: ${(props) => props.gap || 10}px;
  padding-top: ${(props) => props.paddingTop || 0}px;
  padding-right: ${(props) => props.paddingRight || 0}px;
  padding-bottom: ${(props) => props.paddingBottom || 0}px;
  padding-left: ${(props) => props.paddingLeft || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-right: ${(props) => props.marginRight || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  margin-left: ${(props) => props.marginLeft || 0}px;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "auto")};
  align-items: ${(props) => (props.align ? `${props.align}` : "unset")};

  @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
`;


export const Header = styled.div`
    border-bottom: 1px solid ${colors.grey2};
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    background-color: white;
`;

export const Label = styled.label<LabelProps>`
  display: block;
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : "0")};
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${paddingRight}px` : "0"};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : "0"};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? `${paddingLeft}px` : "0"};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "0")};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : "0"};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0"};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "0")};
  font-size: ${({ fontSize }) =>
    `${fontSize ? fontSize : FontSizes.default}rem`} !important;
  color: ${({ color }) => color || "inherit"};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};

  ${({ pointer }) => css`
    ${pointer &&
    css`
        cursor: pointer;
      `}
  `}
  ${({ textTransform }) => textTransform === "capitalize" && css`text-transform: capitalize;`}

  ${({ lineToShow }) => css`
    ${lineToShow &&
    css`
          overflow: hidden;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: ${lineToShow};
          -webkit-box-orient: vertical;
      `}
  `}

  span {
    color: ${colors.error};
  }

  strong {
    font-weight: 500;
  }
`;

export const InfoLabel = styled(Label)`
  background: ${colors.blue1};
  padding: 8px 15px;
  border-radius: 7px;
  display: flex;
  align-items: center;

  svg {
    color: ${colors.primary};
    margin-right: 7px;
    font-size: ${FontSizes.input}rem;
  }
`;
export const PinInput = styled.div`
  color: ${colors.grey2};
  position: relative;
  ${Label} {
    text-align: left;
  }
  input {
    font-size: 18px;
    flex: 1;
    border: 1.5px solid ${colors.grey2};
    border-radius: 6px;
    height: 40px;
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const InlineButton = styled.span`
  cursor: pointer;
  color: ${colors.primary} !important;
  text-decoration: underline;
`;

export const PageWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 20px;
`;


// Define a TypeScript union type for the `type` prop
type TagType = 'success' | 'error' | 'warning' | 'default' | 'info' | 'secondary';

// Helper function to return styles based on type
const getTagStyles = (type: TagType) => {
  switch (type) {
    case 'info':
      return {
        background: `white`,
        color: `${colors.primaryText}`,
        border: `1px solid ${colors.border2}`,
      };
    case 'secondary':
      return {
        background: `${colors.blue1}`,
        color: `${colors.primaryText}`,
        border: `1px solid ${colors.blue2}`,
      };
    case 'success':
      return {
        background: 'green',
        color: 'white',
        border: '1px solid darkgreen',
      };
    case 'error':
      return {
        background: `${colors.error4}50`,
        color: `${colors.primaryText}`,
        border: `1px solid ${colors.error4}70`,
      };
    case 'warning':
      return {
        background: 'yellow',
        color: 'black',
        border: '1px solid orange',
      };
    default:
      return {
        background: 'blue',
        color: 'white',
        border: '1px solid darkblue',
      };
  }
};

// Styled component using the `TagType` for the `type` prop
export const CustomTag = styled.div<{ type: TagType }>`
  ${({ type }) => {
    const { background, color, border } = getTagStyles(type);
    return `
      background: ${background};
      color: ${color};
      border: ${border};
    `;
  }}
  font-size: ${FontSizes.error}rem;
  padding: 2px 8px;
  border-radius: 13px;
  display: inline-block;
  align-items: center
`;

export const InfoPageWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  img {
    max-width: 300px;
  }
`;
