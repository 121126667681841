import { ActionTypes } from "Utils/enums";
import { useDispatch } from "react-redux";
import { useUserDetails } from "./useUserDetails";
import { fetchData } from "Utils/fetch";
import { restAPIs } from "Utils/restAPIs";
import { useState } from "react";

export const useOrganization = () => {
  const { organizationId } = useUserDetails();
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);

  const updateOrganizationData = async (successToast?: string) => {
    setIsLoading(true);
    const orgDetails = await fetchData(
      restAPIs.getOrganizationDetails(organizationId, successToast)
    );

    const {
      data: { subscriptionDetails, subscriptionId, name },
    } = orgDetails;

    dispatch({
      type: ActionTypes.USER_DETAILS,
      value: {
        subscriptionId,
        isPaidPlan: subscriptionDetails?.isPaidPlan,
        status: subscriptionDetails?.status,
        currentSubscription: orgDetails?.data,
        organizationName: name,
      },
    });
    setIsLoading(false);
    if(orgDetails?.data) return true
  };

  return { updateOrganizationData, isOrganizationFetching: loading };
};
