// Core
import React, { useEffect, useState } from "react";
import AutoComplete from "antd/es/auto-complete";
import { Flex } from "@rebass/grid";
import Spin from "antd/es/spin";

// Others
import { fetchData } from "Utils/fetch";
import { useDebounce } from "hooks/useDebounceSearch";
import { useUserDetails } from "hooks/useUserDetails";
import { restAPIs } from "Utils/restAPIs";

// Components
import UserHead from "Components/UIBlocks/userHead";

const { Option } = AutoComplete;

type UserOption = {
  _id: string;
  label: string;
  value: string;
  url?: string;
};

type UserSelectProps = {
  onUserSelect: (selectedUser: UserOption | undefined) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
};

const OrgUserSelect: React.FC<UserSelectProps> = ({
  onUserSelect,
  label,
  placeholder = "Enter email/name to search",
  disabled,
}) => {
  const { organizationId } = useUserDetails();
  const [email, setEmail] = useState<string>("");
  const [userList, setUserList] = useState<UserOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedEmail = useDebounce(email, 500);

  const handleEmailCheck = async () => {
    setLoading(true);
    const res = await fetchData(
      restAPIs.getOrgUsers({
        page: 1,
        pageSize: 20,
        searchQuery: debouncedEmail,
        currentOrganizationId: organizationId,
      })
    );
    if (res.data) {
      const filteredOptions = res.data?.results.map((item: any) => ({
        _id: item._id,
        label: `${item?.personalInformation.firstName} ${item?.personalInformation.lastName}`,
        value: item.email, // Assuming value refers to email
        url: item.personalInformation?.profilePicKey,
      }));
      setUserList(filteredOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (debouncedEmail) handleEmailCheck();
  }, [debouncedEmail]);

  const handleSearch = (value: string) => {
    setEmail(value);
    if (!value) {
      // If the user clears the input, send undefined
      onUserSelect(undefined);
    }
  };

  const onSelect = (value: string) => {
    setEmail?.(value);
    const selectedUser = userList.find((user) => user.value === value);
    onUserSelect(selectedUser || undefined);
  };

  return (
    <AutoComplete
      onSearch={handleSearch}
      allowClear
      onSelect={onSelect}
      value={email as string}
      disabled={disabled}
      placeholder={placeholder || label}
    >
      {loading ? (
        <Option key="loading" value="loading">
          <Flex padding="5px" justifyContent="center">
            <Spin size="small" />
          </Flex>
        </Option>
      ) : (
        userList?.map((option) => (
          <Option key={option.value} value={option.value}>
            <UserHead
              url={option.url}
              label={option.label}
              email={option.value}
            />
          </Option>
        ))
      )}
    </AutoComplete>
  );
};

export default OrgUserSelect;
