// Core
import { combineReducers } from 'redux';
import { Reducer } from 'redux';

/// Reducers
import userDetailsReducer from './userDetails.reducer';
import globalStateReducer from './globalState.reducer';

// Types
import { Action, RootState } from './types';

const rootReducer: Reducer<RootState, Action> = combineReducers({
  userDetailsReducer,
  globalStateReducer
});

export default rootReducer;