import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FontSizes } from "Utils/enums";
import { colors } from "Assets/Styles/colors";

export const MenuList = styled.div`
  .active {
    background-color: ${colors.blue4};
    padding-left: 20px;

      * {
        color: ${colors.white};
      }
    }

    a:hover, a:active {
    color: ${colors.white};
  }
`
export const SideBarWrapper = styled.div`
  padding: 20px;

  img {
    max-width: 125px;
    margin: 20px 0 30px;
  }
`
export const MenuItem = styled(NavLink)`
    padding: 10px 20px;
    padding-left: 0;
    margin-bottom: 10px;
    border-radius: 8px;
    display: flex;
    transition: all ease-in-out 0.2s;
    align-items: center;
    cursor: pointer;
    font-size: ${FontSizes.sidebar}rem;
    color: ${colors.grey9};
    
    svg {
      font-size: ${FontSizes.sidebarIcon}rem;
      margin-right: 8px;
    }

    .icon-wrapper {
      margin-right: 8px;
    }

    &:hover {
      transition: all ease-in-out 0.2s;
      * {
        color: white !important;
      }
    }
`