import { colors } from "Assets/Styles/colors";
import { FontSizes } from "Utils/enums";
import styled from "styled-components";

interface StatusTagProps {
  status: 'warning' | 'success' | 'info'; 
}

const getPlanWrapperColor = (status: StatusTagProps['status']) => {
  switch (status) {
    case 'warning':
      return {
        background: 'linear-gradient(90deg, #FFF0F0 -34.57%, #FFFFFF 80.44%)',
      };
    case 'success':
      return {
        background: colors.blue6,
      };
    case 'info':
      return {
        background: colors.blue6,
      };
  }
};

export const PlanWrapper = styled.div<StatusTagProps>`
    border: 1px solid ${colors.border};
    background: ${({ status }) => getPlanWrapperColor(status).background};
    padding: 15px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;;


const getStatusColor = (status: StatusTagProps['status']) => {
  switch (status) {
    case 'warning':
      return {
        color: colors.error4,
        background: colors.error3,
      };
    case 'success':
      return {
        color: colors.success,
        background: colors.success4,
      };
    case 'info':
      return {
        color: colors.primary,
        background: colors.blue2,
      };
  }
};

export const StatusTag = styled.div<StatusTagProps>`
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px 13px;
  font-size: ${FontSizes.table}rem;
  color: ${({ status }) => getStatusColor(status).color};
  background-color: ${({ status }) => getStatusColor(status).background};

  .anticon {
    margin-right: 5px;
  }
`;