import React from "react";
import { Navigate } from "react-router-dom";
import { SubscriptionStatus } from "Utils/enums";
import RenewSubscription from "Pages/Auth/RenewSubscription/loadable";
import { INITIAL_ROUTE } from "config/constants";
import { checkExpiry } from "Utils/helpers";

const RenewSubscriptionRoute = ({ status }: { status: SubscriptionStatus | undefined }) => {
  if (status === SubscriptionStatus.Active) {
    return <Navigate to={INITIAL_ROUTE} />;
  } else if (status !== null && checkExpiry(status!)) {
    return <RenewSubscription />;
  } else {
    return <Navigate to="/profile-setup" />;
  }
};

export default RenewSubscriptionRoute;
