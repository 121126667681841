// import { DownloadOutlined } from "@ant-design/icons";

// Components
// import Button from "Components/Button";
import { PlanType } from "Utils/enums";

// Others
import { formatDate } from "Utils/helpers";

export const getSubscriptionColumns = () => [
  {
    title: "Billing Period",
    dataIndex: "name",
    key: "name",
    render: (_: string, record: any) =>
      `${formatDate(record.invoiceFrom)} - ${formatDate(record.invoiceTo)}`,
  },
  {
    title: "Invoice #",
    dataIndex: "invoiceId",
    key: "invoiceId",
  },
  {
    title: "Amount",
    dataIndex: "amountPaid",
    key: "amountPaid",
    render: (amountPaid: number) => `$${amountPaid / 100}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  // {
  //   title: "Actions",
  //   dataIndex: "invoiceUrl",
  //   key: "invoiceUrl",
  //   width: 150,
  //   render: (invoiceUrl: string) => (
  //     <Button
  //       type="dashed"
  //       onClickHandler={handlePdfDownload(invoiceUrl)}
  //       icon={<DownloadOutlined />}
  //       size="small"
  //     />
  //   ),
  // },
];

export const planMap: Record<PlanType, string> = {
  [PlanType.TRADITIONAL]: 'Standard',
  [PlanType.UNLIMITED]: 'Premium',
  [PlanType.FREE]: "Free"
};

// const handlePdfDownload = (url: string) => () => {
//   const link = document.createElement("a");
//   link.href = url;
//   link.download = "downloaded_file_name"; // Specify the desired file name
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };
