// Assuming USER_DETAILS is a string constant in 'config/actionTypes'
import { ActionTypes } from 'Utils/enums';
import { UserDetailsState } from './types';
import { userInitialState } from './initialState';

// Reducer function
const userDetailsReducer = (
  state: UserDetailsState = userInitialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.USER_DETAILS:
      return {
        ...state,
        ...action.value,
      };
    case ActionTypes.UPDATE_PERSONAL_INFORMATION:
      return {
        ...state,
        personalInformation: {
          ...(state.personalInformation),
          ...action.value,
        },
      };
    case ActionTypes.UPDATE_USER_COUNTS_INFORMATION:
      return {
        ...state,
        userDetailsCount: {
          ...state.userDetailsCount,
          [action.value.key]: action.value.value,
        },
      };

    default:
      return state;
  }
};

export default userDetailsReducer;
