import React from "react";
import { Navigate } from "react-router-dom";
import { SubscriptionStatus } from "Utils/enums";
import { INITIAL_ROUTE } from "config/constants";
import SignUpStep from "Pages/Auth/SignUpSteps/loadable";
import { checkExpiry } from "Utils/helpers";

const ProfileSetupRoute = ({ status }: { status: SubscriptionStatus | undefined }) => {
  if (status === SubscriptionStatus.Active) {
    return <Navigate to={INITIAL_ROUTE} />;
  } else if (status !== null && checkExpiry(status!)) {
    return <Navigate to="/renew-subscription" />;
  } else {
    return <SignUpStep />;
  }
};

export default ProfileSetupRoute;
