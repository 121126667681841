import React from "react";

// Style
import { StyledButton } from "./styles";
import { Props } from "./types";
import { Label } from "Assets/Styles/globalStyles";
import { colors } from "Assets/Styles/colors";

// Types
import { FontSizes } from "Utils/enums";

// Others
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Tag } from "antd";

const CustomButton: React.FC<Props> = (props: Props) => {
  const {
    size,
    marginTop,
    label,
    onClickHandler,
    marginBottom,
    color = colors.primary,
    type = "primary",
    width,
    loading,
    disabled,
    icon,
    background,
    tagCount,
    className,
    htmlType,
    form,
  } = props;

  return (
    <StyledButton
      {...{ marginBottom, marginTop, width }}
      onClick={onClickHandler}
      type={type}
      htmlType={htmlType}
      form={form}
      loading={loading}
      disabled={disabled || loading}
      backgroundColor={color}
      background={background}
      className={className}
      icon={icon}
      size={size as SizeType}
    >
      {label && (
        <Label fontSize={FontSizes.button}>
          {label} {!!tagCount && <Tag color={colors.error}>{tagCount}</Tag>}
        </Label>
      )}
    </StyledButton>
  );
};

export default CustomButton;
