import { colors } from "Assets/Styles/colors";
import { Root, ToothConfig } from "./interfaces";
import { FollowUpPriority, FollowupStatus } from "Pages/CreateCase/TimeLine/types";

export enum FontSizes {
  default = 1,
  input = 0.8,
  btnIcon = 0.8,
  table = 0.7,
  floatingLabel = 0.7,
  checkbox = 0.7,
  button = 0.7,
  tableAction = 0.7,
  small = 0.7,
  sidebar = 0.8,
  sidebarIcon = 1,
  error = 0.6,
  info = 0.5,
  tag = 0.6,
  h1 = 1.5,
  priceTag = 2.0,
  h2 = 1.2,
  h3 = 1,
  h4 = 0.9,
  h6 = 0.8,
}

export enum FontWeight {
  light = 300,
  default = 400,
  medium = 500,
  bold = 600,
  bolder = 700,
}

export enum SubscriptionStatus {
  Active = 'active',
  Invite = 'invite',
  Expired = 'expired',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Paused = 'paused',
  Canceled = 'canceled',
  OnboardingComplete = 'OnboardingComplete'
}

export enum AuthStatus {
  AUTHENTICATED = "authenticated",
  CONFIGURING = "configuring",
}

export enum USER_ORIGIN {
  HCP_INVITE = "HcpInvite",
  COLLABORATOR_INVITE = "CollaboratorInvite",
}

export enum PlanType {
  FREE = "Free",
  UNLIMITED = "Unlimited",
  TRADITIONAL = "Traditional",
}

export enum ToothAffectedPart {
  CROWN = "Crown",
  ROOT = "Root",
  BOTH = "Both",
  NONE = "NONE",
}

export enum PlanPeriod {
  Month = "month",
  Year = "year",
  Free = "free",
}

export enum ToothDiagramFilters {
  ALL = "All",
  EXISTING_ONLY = "Existing Only",
  PLANNED_ONLY = "Planned Only",
}

export const UserStatus = {
  OnboardingComplete: "Verified",
  EmailVerified: "Verified",
  Accepted: "Accepted",
  Invited: "Invitation Sent",
  completed: "Completed",
  inProgress: "In Progress"
};

export enum CaseStatus {
  completed = "completed",
  inProgress = "inProgress",
  onHold = "onHold",
  closed = "closed"
};

export const CaseStatusMap: { [key: string]: string } = {
  [CaseStatus.onHold]: "On Hold",
  [CaseStatus.completed]: "Completed",
  [CaseStatus.inProgress]: "In Progress",
  [CaseStatus.closed]: "Closed"
}

export const ColorMap: { [key: string]: string } = {
  OnboardingComplete: colors.success2,
  completed: colors.success2,
  Accepted: colors.success2,
  EmailVerified: colors.success2,
  Invited: colors.orange,
  inProgress: colors.orange,
  [FollowupStatus.Resolved]: colors.success2,
  [FollowupStatus.Open]: colors.orange,
  [FollowUpPriority.Medium]: colors.orange,
  [FollowUpPriority.Urgent]: colors.error2,
  [FollowUpPriority.Low]: colors.success2,
};

export enum ActionTypes {
  USER_DETAILS = "userDetails",
  UPDATE_PERSONAL_INFORMATION = "updatePersonalInformation",
  UPDATE_USER_COUNTS_INFORMATION = "UPDATE_USER_COUNTS_INFORMATION",
  PLAN_UPDATING = "planUpdating",
  AI_PROCESSING = "aiProcessing",
}

export enum OrganizationRoleEnum {
  OrganizationOwner = "OrganizationOwner",
  Doctor = "Doctor",
  Staff = "Staff",
}

export enum TreatmentTypeEnum {
  rootOnly = "ROOT_ONLY",
  implantOnly = "IMPLANT_ONLY",
  implantCrownScrewRetained = "IMPLANT_CROWN_SCREW_RETAINED",
  implantCrownCemented = "IMPLANT_CROWN_CEMENTED",
  implantCrownRemovable = "IMPLANT_CROWN_REMOVABLE",
  implantOnlyWithAttachment = "IMPLANT_CROWN_ATTACHMENT_ONLY",
  crown = "CROWN",
  temporaryCrown = "TEMPORARY_CROWN",
  extraction = "EXTRACTION",
  plannedExtraction = "PLANNED_EXTRACTION",
  splinted = "SPLINTED_TEETH",
  temporarySplinted = "TEMPORARY_SPLINTED_TEETH",
  pontic = "PONTIC",
  removable = "REMOVABLE",
  barScrewRetained = "BAR_SCREW_RETAINED",
  barCemented = "BAR_CEMENTED",
  barAttachment = "BAR_ATTACHMENT",
  barOverdenture = "BAR_OVERDENTURE",
}

const ImplantCrownSubOptions = [
  {
    value: TreatmentTypeEnum.implantOnly,
    label: "Implant Only",
  },
  {
    value: TreatmentTypeEnum.implantCrownScrewRetained,
    label: "Screw Retained Crown",
  },
  {
    value: TreatmentTypeEnum.implantCrownCemented,
    label: "Cemented Retained Crown",
  },
  {
    value: TreatmentTypeEnum.implantCrownRemovable,
    label: "Removable with Attachment",
  },
  {
    value: TreatmentTypeEnum.implantOnlyWithAttachment,
    label: "Attachment Only",
  },
];

export const ImplantAttachments: Root['state'][] = ['IMPLANT_ONLY', 'SCREW_RETAINED', 'CEMENTED', 'REMOVABLE', 'ATTACHMENT_ONLY'];

export const contentTypeMappings: { [key: string]: string } = {
  'jpeg': 'image/jpeg',
  'png': 'image/png',
  'pdf': 'application/pdf',
  'ppt': 'application/vnd.ms-powerpoint',
  'txt': 'text/plain',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'doc': 'application/msword',
};

const BarSubOptions = [
  {
    value: TreatmentTypeEnum.barScrewRetained,
    label: "Screw Retained Bar",
  },
  {
    value: TreatmentTypeEnum.barCemented,
    label: "Cement Retained Bar",
  },
  {
    value: TreatmentTypeEnum.barAttachment,
    label: "Bar Attachments",
  },
  {
    value: TreatmentTypeEnum.barOverdenture,
    label: "Overdenture",
  },
];

export const CaseTypeOptions = [
  {
    value: TreatmentTypeEnum.crown,
    label: "Crown",
  },
  {
    value: TreatmentTypeEnum.rootOnly,
    label: "Root Only",
  },
  {
    value: "implantCrown",
    label: "Implant",
    subOptions: ImplantCrownSubOptions,
  },
  {
    value: TreatmentTypeEnum.extraction,
    label: "Extracted",
  },
  {
    value: TreatmentTypeEnum.plannedExtraction,
    label: "Planned Extraction",
  },
  {
    value: TreatmentTypeEnum.splinted,
    label: "Splinted",
  },
  {
    value: TreatmentTypeEnum.pontic,
    label: "Pontic",
  },
  {
    value: TreatmentTypeEnum.removable,
    label: "Removable",
  },
  {
    value: "bar",
    label: "Bar",
    subOptions: BarSubOptions,
  },
];

export const ToothDefaultConfig: ToothConfig = {
  crown: {
    color: '#F2EFE8',
  },
  root: {
    color: '#F2EFE8',
  },
};

export const HELP_TEXT_TREATMENT: Partial<Record<TreatmentTypeEnum, string>> = {
  [TreatmentTypeEnum.barAttachment]: "After setting up bar. You can add/remove attachment from the bar by clicking on the required tooth",
}
