// Assuming USER_DETAILS is a string constant in 'config/actionTypes'
import { ActionTypes } from 'Utils/enums';
import { globalInitialState } from './initialState';
import { GlobalState } from './types';

const globalStateReducer = (
  state: GlobalState = globalInitialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.PLAN_UPDATING:
      return {
        ...state,
        planUpdating: action.value,
      };
    case ActionTypes.AI_PROCESSING:
      return {
        ...state,
        aiImageProcessing: action.value,
      };

    default:
      return state;
  }
};

export default globalStateReducer;
